import JwtServices from "../../../services/jwt.services";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

import UserTopNav from "../../../components/UserTopNav.vue";

export default {
  components: { UserTopNav, apexchart: VueApexCharts },
  data: () => ({
    loadingUploadFile: false,
    loadingUploadAlarm: false,
    uploadAlarmDialog: false,
    disableSendFileButton: true,
    data_alarm_resolved: [],
    uploadAlarmFile: [],
    selectedTab: "update",
    loadingOverlay: true,
    chartSeverities: [
      { title: "Critical", value: "critical" },
      { title: "Major", value: "major" },
      { title: "Medium", value: "medium" },
      { title: "Minor", value: "minor" },
      { title: "Not Yet Identified", value: "not_yet_identified" },
    ],
    loading: true,
    data_chart: [3, 0],
    data_chart_status: {},
    total_summary_data: [],
    selected_chart: "critical",
    data_chart_range: {},
    date_picker_alarm_1: false,
    date_picker_alarm_2: false,
    dateAlarm: {
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      default: true,
      defaultTable: true,
      defaultText: "Select Date",
    },
    startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    date_picker_1: false,
    date_picker_2: false,
    defaultDate: true,
    defaultText: "Select Date",
    user: null,
    network_operator_id: null,
    dialog_confirmation: false,
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 50, 100, 250, 500],
    },
    selected_severity: "",
    list_technology: ["2G", "3G", "4G"],
    list_severirty: [
      "Critical",
      "Major",
      "Medium",
      "Minor",
      "Not Yet Identified",
    ],
    list_periode: ["30 Hari Terakhir", "7 Hari Terakhir"],
    headers: [
      {
        text: "Created Time",
        value: "created_at",
        sortable: false,
      },
      { text: "Ticket Number", value: "ticket_number", sortable: false },
      { text: "Operator", value: "operator_name", sortable: false },
      { text: "Summary", value: "summary", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Current Process", value: "current_process", sortable: false },
      { text: "Severity", value: "severity", sortable: false },
      { text: "Affected Node", value: "affected_node", sortable: false },
      { text: "Updated Time", value: "updated_at", sortable: false },
    ],
    data_alarm: [],
    selected_data_alarm: [],
  }),
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY - HH:mm:ss");
    },
  },
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    threeMonthsDate() {
      return moment().subtract(3, "months").format("YYYY-MM-DD");
    },
    dateDisplay: {
      //convert html standard datetime value into local time
      get() {
        return {
          startDate: moment(this.dateAlarm.startDate)
            .locale("id-ID")
            .format("DD-MM-YYYY"),
          endDate: moment(this.dateAlarm.endDate)
            .locale("id-ID")
            .format("DD-MM-YYYY"),
          threemonthago: moment().subtract(2, "month").format("YYYY-MM-DD"),
        };
      },
    },
  },
  mounted() {
    this.user = JwtServices.getUser();
    this.network_operator_id = this.user.network_operator.id;
    this.initialize();
  },

  methods: {
    initialize() {
      this.getSummaryRange();
      this.getSummaryStatus();
      const payload = {
        severity: "",
        startDate: "",
        endDate: "",
        page: 1,
        limit: 10,
      };
      this.getAlarm(payload);
    },
    resetFilter() {
      this.dateAlarm.default = true;
      const payload = {
        limit: 10,
        page: 1,
        startDate: "",
        endDate: "",
        severity: "",
      };
      if (this.selectedTab === "update") {
        this.getAlarm(payload);
      } else {
        this.getAlarmHistory(payload);
      }
    },
    resetFilterDataChart() {
      this.startDate = this.currentDate;
      this.endDate = this.endDate;
      this.loadingOverlay = true;
      this.getSummaryStatus();
    },
    async getSummaryRange() {
      const url = `${process.env.VUE_APP_API_URL}api/v1/${this.user.language}/fo-alarm/summary/date-range`;

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.user.token}`,
        },
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.error) {
          this.$swal({
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          this.data_chart_range = data.data;
        }
      } catch (error) {
        this.$swal({
          text: error.message,
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
        this.loadingOverlay = false;
      }
    },

    async getSummaryStatus() {
      this.loadingOverlay = true;
      const url = `${process.env.VUE_APP_API_URL}api/v1/${this.user.language}/fo-alarm/summary/severity-status?start_date=${this.startDate}&end_date=${this.endDate}`;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.user.token}`,
        },
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.error) {
          this.$swal({
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          const transformedData = {};
          Object.keys(data.data).forEach((key) => {
            transformedData[key] = [
              data.data[key].active_alarm,
              data.data[key].closed_alarm,
            ];
          });
          this.data_chart_status = transformedData;
        }
      } catch (error) {
        this.$swal({
          text: error.message,
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
        this.loadingOverlay = false;
      }
    },
    clearSeverity() {
      this.selected_severity = "";
    },
    inputDate(val) {
      if (val == "table") {
        this.dateAlarm.defaultTable = false;
      } else {
        this.dateAlarm.default = false;
      }
    },
    getAlarmByPage() {
      const payload = {
        limit: this.pagination.rowsPerPage,
        page: this.pagination.page,
        startDate: this.dateAlarm.default ? "" : this.dateAlarm.startDate,
        endDate: this.dateAlarm.default ? "" : this.dateAlarm.endDate,
        severity: this.selected_severity,
      };
      this.getAlarm(payload);
    },
    applyFilter() {
      const payload = {
        startDate: this.dateAlarm.default ? "" : this.dateAlarm.startDate,
        endDate: this.dateAlarm.default ? "" : this.dateAlarm.endDate,
        limit: this.pagination.rowsPerPage,
        page: 1,
        severity: this.selected_severity,
      };

      if (this.selectedTab === "update") {
        this.getAlarm(payload);
      } else {
        this.getAlarmHistory(payload);
      }
    },
    getAlarmByRow() {
      const payload = {
        limit: this.pagination.rowsPerPage,
        page: 1,
        startDate: this.dateAlarm.default ? "" : this.dateAlarm.startDate,
        endDate: this.dateAlarm.default ? "" : this.dateAlarm.endDate,
        severity: this.selected_severity,
      };
      this.getAlarm(payload);
    },
    async getAlarm(payload) {
      this.loading = true;
      try {
        const baseUrl = `${process.env.VUE_APP_API_URL}api/v2/${this.user.language}/fo-alarm/`;
        const queryParams = new URLSearchParams({
          severity: payload.severity,
          start_date: payload.startDate,
          end_date: payload.endDate,
          limit: payload.limit || 10,
          page: payload.page || 1,
          status: "open",
        });
        const url = `${baseUrl}?${queryParams}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        });
        const result = await response.json();
        this.loading = false;
        this.data_alarm = [];
        this.loading_overlay = false;
        // Check error
        if (result.error) {
          this.data_alarm = [];
          this.pagination.page = 1;
          this.pagination.totalItems = 1;
          return;
        }
        this.data_alarm = result.data.data;
        this.pagination.page = result.data.pagination.current_page;
        this.pagination.totalItems = result.data.pagination.total_page;
      } catch (err) {
        this.data_alarm = [];
        this.loading_overlay = false;
        // You might want to add additional error handling here
        this.pagination.page = 1;
        this.pagination.totalItems = 1;
      }
    },
    async getAlarmHistory(payload) {
      this.loading = true;
      try {
        // HARDCODED
        const baseUrl = `${process.env.VUE_APP_API_URL}api/v2/${this.user.language}/fo-alarm/`;

        const queryParams = new URLSearchParams({
          severity: payload.severity,
          start_date: payload.startDate,
          end_date: payload.endDate,
          limit: payload.limit || 10,
          page: payload.page || 1,
          status: "closed",
        });
        const url = `${baseUrl}?${queryParams}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        });
        const result = await response.json();
        this.loading = false;
        this.data_alarm_resolved = [];
        this.loading_overlay = false;
        // Check error
        if (result.error) {
          this.data_alarm = [];
          this.pagination.page = 1;
          this.pagination.totalItems = 1;
          return;
        }
        this.data_alarm_resolved = result.data.data;
        this.pagination.page = result.data.pagination.current_page;
        this.pagination.totalItems = result.data.pagination.total_page;
      } catch (err) {
        this.data_alarm = [];
        this.loading_overlay = false;
        this.pagination.page = 1;
        this.pagination.totalItems = 1;
      }
    },

    dragover(event) {
      event.preventDefault();
    },

    dragenter(event) {
      document
        .getElementById("dropArea")
        .classList.add("light-blue", "lighten-5");
    },
    dragleave(event) {
      // Clean up visual fluff
      document
        .getElementById("dropArea")
        .classList.remove("light-blue", "lighten-5");
    },

    drop(event) {
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      this.adduploadAlarmFiles(); // Trigger the onChange event manually
    },
    removeuploadAlarmFiles(i) {
      this.uploadAlarmFile.splice(i, 1);
      if (this.uploadAlarmFile.length === 0) {
        this.disableSendFileButton = true;
        document
          .getElementById("dropArea")
          .classList.remove("light-blue", "lighten-5");
      }
    },
    // Download Template file for Bulk Close Ticket
    downloadTemplateuploadAlarmFile() {
      const link = document.createElement("a");
      link.href = `${process.env.BASE_URL}PMT_TEMPLATE_FO.xlsx`;
      link.download = "PMT_TEMPLATE_FO.xlsx";
      link.click();
    },
    adduploadAlarmFiles() {
      this.uploadAlarmFile = [...this.$refs.fileInput.files];
      this.loadingBulkCloseTicket = true;
      if (this.uploadAlarmFile.length > 0) {
        this.disableSendFileButton = false;
      }
      setTimeout(() => {
        this.loadingBulkCloseTicket = false;
      }, 2000);
    },
    selectUploadAlarmFiles() {
      this.$refs.fileInput.click();
    },
    sendUploadAlarmFile() {
      this.loading_overlay = true;

      const form = new FormData();
      form.append("attachment", this.uploadAlarmFile[0]);
      console.log(this.uploadAlarmFile[0]);

      fetch(
        process.env.VUE_APP_API_URL +
          `api/v1/${this.user.language}/upload/fo-alarm`,
        {
          method: "POST",
          body: form,
          headers: {
            Authorization: `Bearer ` + this.user.token,
          },
        }
      )
        .then((response) => {
          this.response = response;
          return response.json();
        })
        .then((result) => {
          this.loading_overlay = false;
          this.uploadAlarmDialog = false;
          this.resetFilter();
          // Check error
          if (result.error) {
            this.$swal("Opps", result.message, "error");
            return;
          } else {
            this.$swal("Success", result.message, "success");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSeverity(item) {
      if (item.severity.toLowerCase() === "critical") {
        return 1;
      } else if (item.severity.toLowerCase() === "major") {
        return 2;
      } else if (item.severity.toLowerCase() === "medium") {
        return 2;
      } else if (item.severity.toLowerCase() === "minor") {
        return 4;
      } else if (item.severity.toLowerCase() === "not yet identified") {
        return 5;
      }
    },
    resolveAlarm() {
      this.loading_overlay = true;
      const transormData = this.selected_data_alarm.map((item) => {
        return {
          id: item.id,
          start_time: item.start_time,
          end_time: item.end_time,
          target_to_resolved: item.target_to_resolved,
          operator_id: item.cell_operator_id,
          operator_name: this.user.network_operator.name,
          ticket_number: item.ticket_number,
          summary: item.summary,
          description: item.description,
          status: item.status,
          current_process: item.current_process,
          severity: this.getSeverity(item),
          affected_node: item.affected_node,
          affected_segment: item.affected_segment,
          created_at: item.created_at,
          updated_at: item.updated_at,
          cell_operator_id: item.cell_operator_id,
        };
      });
      let data = {
        alarm_data: JSON.stringify(transormData),
      };

      fetch(process.env.VUE_APP_API_URL + `api/v1/id/alarm/fo/update`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + this.user.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.loading_overlay = false;
          if (result.error == true) {
            this.$swal("Opps", result.message, "error");
            return;
          }

          this.dialog_confirmation = false;
          this.$swal({
            title: "Success",
            text: result.message,
            timer: 1000,
            showConfirmButton: false,
            icon: "success",
          });
          this.switchTab("history");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    switchTab(tab) {
      this.selectedTab = tab;
      this.dateAlarm.default = true;
      const payload = {
        limit: 10,
        page: 1,
        startDate: "",
        endDate: "",
        severity: "",
      };
      if (tab === "update") {
        this.getAlarm(payload);
      } else {
        this.getAlarmHistory(payload);
      }
    },
  },
};
