export default {
	data() {
		return {
			colorList: [
				"#FFB900",
				"#c8cf00",
				"#B50E0E",
				"#E81123",
				"#B4009E",
				"#5C2D91",
				"#0078D7",
				"#134889",
				"#ff0087",
				"#008272",
				"#107C10",
				"#2cabe3",
				"#74b8f6",
				"#7113d6",
				"#066056",
				"#cc6e34",
				"#cc57ca",
				"#a01ef4",
				"#627c60",
			],
		};
	},
	methods: {
		avatarBackground(value) {
			let requestText = value.toLowerCase();
			let separateText = requestText.split("");
			let sum = 0;
			for (const i of separateText) {
				sum += requestText.charCodeAt(i);
			}
			const percentage = sum % this.colorList.length;
			return this.colorList[percentage];
		},
		stringInitial(value) {
			let str = value;
			let matches = str.match(/\b(\w)/g);
			let acronym = matches.join("");
			return acronym.slice(0, 2);
		},
	},
};
