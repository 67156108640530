import JwtServices from '../../services/jwt.services';
export default {
	computed: {
		withoutChild() {
			let withoutChild = [];
			if (this.network_operator == 1) {
				for (const nav of this.navbar_cell) {
					if (nav.child.length == 0) {
						withoutChild.push(nav);
					}
				}
			} else if (this.network_operator == 2) {
				for (const nav of this.navbar_fo) {
					if (nav.child.length == 0) {
						withoutChild.push(nav);
					}
				}
			}

			return withoutChild;
		},
		withChild() {
			let withChild = [];
			if (this.network_operator == 1) {
				for (const nav of this.navbar_cell) {
					if (nav.child.length != 0) {
						withChild.push(nav);
					}
				}
			} else {
				for (const nav of this.navbar_fo) {
					if (nav.child.length != 0) {
						withChild.push(nav);
					}
				}
			}

			return withChild;
		}
	},
	mounted() {
		if (
			this.$vuetify.breakpoint.name === 'md' ||
			this.$vuetify.breakpoint.name === 'sm' ||
			this.$vuetify.breakpoint.name === 'xs'
		) {
			this.mini = true;
		}
		this.user = JwtServices.getUser();
		if (!this.user) {
			this.$router.replace({ name: 'Login' });
		}
		this.network_operator = this.user.network_operator.type;
		// console.log(this.network_operator);
	},
	data: () => ({
		sub_nav_active: [],
		network_operator: '',
		navbar_cell: [
			{
				title: 'Submit Alarm',
				link: '/dashboard/submit-alarm',
				icon: 'mdi-transmission-tower',
				child: [
					{
						title: 'Upload File',
						link: '/dashboard/submit-alarm/upload',
						icon: 'mdi-transmission-tower'
					}
				]
			},
			{
				title: 'Update Status Alarm',
				icon: 'mdi-text-box-check-outline',
				link: '/dashboard/update-status-alarm',
				child: []
			},
			{
				title: 'History Alarm',
				icon: 'mdi-monitor-dashboard',
				link: '/dashboard/history-alarm',
				child: []
			}
		],
		navbar_fo: [
			{
				title: 'History Alarm',
				icon: 'mdi-monitor-dashboard',
				link: '/dashboard/history-alarm-fo',
				child: []
			}
		],
		mini: false,
		drawer: true,
		activeItem: 'Executive Dashboard'
	}),
	methods: {
		isActive(menuItem) {
			return this.activeItem === menuItem;
		},
		setActive(menuItem) {
			this.activeItem = menuItem;
		}
	},

	created() {
		this.setActive(this.$route.path);
		this.isActive(this.$route.path);
	}
};
