import JwtServices from "../../../services/jwt.services";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

import UserTopNav from "../../../components/UserTopNav.vue";
import TicketingTable from "../../../components/ticketing/TicketingTable.vue";

export default {
  components: { UserTopNav, apexchart: VueApexCharts, TicketingTable },
  data: () => ({
    loadingOverlay: false,
    baseURL: process.env.VUE_APP_API_URL,
    total_summary_data: [],

    data_chart: [],

    // DATE PICKER
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date_picker_1: false,
    date_picker_2: false,
    defaultDate: true,
    defaultText: "YYYY-MM-DD",

    // chart option
    sourceOptions: {
      chart: {
        width: 240,
        type: "donut",
        dropShadow: {
          enabled: true,
          color: "#111",
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2,
        },
      },
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: "",
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
              value: {
                show: true,
                fontSize: "2.5em",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: undefined,
                offsetY: -8,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
      },
      labels: ["Open ", "On Progress ", "Closed "],
      legend: {
        show: true,
        position: "bottom",
        fontSize: "15px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 400,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.globals.seriesTotals[opts.seriesIndex];
        },
      },
      states: {
        hover: {
          filter: "none",
        },
      },
      colors: ["#FFB300", "#43A047", "#1E88E5"],
      noData: {
        text: "No Tickets Available",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#000",
          fontSize: "20px",
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },

    // params for chart filtering
    params: {
      include_total: true,
      start_date: "",
      end_date: "",
    },

    user: null,
    network_operator_id: null,
  }),
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY - HH:mm:ss");
    },
  },
  created() {
    this.user = JwtServices.getUser();
    this.network_operator_id = this.user.network_operator.id;
    this.initialize();
  },

  methods: {
    initialize() {
      this.getTotalSummaryTicket(this.params);
    },

    filterDataChart() {
      if (this.defaultDate) {
        this.params.start_date = "";
        this.params.end_date = "";
      } else {
        this.params.start_date = this.startDate;
        this.params.end_date = this.endDate;
      }

      this.getTotalSummaryTicket(this.params);
    },

    resetFilterDataChart() {
      this.params.start_date = "";
      this.params.end_date = "";
      this.defaultDate = true;
      this.getTotalSummaryTicket(this.params);
    },

    async getTotalSummaryTicket(params) {
      this.loadingOverlay = true;
      try {
        const url = new URL(
          process.env.VUE_APP_API_URL + `api/v2/id/ticket/summary/ticket-status`
        );

        for (let key in params) {
          url.searchParams.append(key, params[key]);
        }

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        });

        if (response.status === 401) {
          this.logout();
          return;
        }

        const result = await response.json();
        if (result.error) {
          this.$swal({
            text: result.message,
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
          });
        } else {
          if (this.total_summary_data.length === 0) {
            let array = result.data;
            let lastData = array.pop();
            array.unshift(lastData);
            this.total_summary_data = array;
          }
          const dataChart = result.data
            .filter((item) => item.name !== "Total")
            .map((item) => item.count);

          this.data_chart = dataChart;
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          text: "Terjadi Kesalahan",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
        });
      } finally {
        this.loadingOverlay = false;
      }
    },

    styleForCardSummary(name) {
      const styles = {
        Total: "pa-3 red darken-1 rounded-lg",
        Open: "pa-3 orange darken-1 rounded-lg",
        "On Progress": "pa-3 green darken-1 rounded-lg",
        Closed: "pa-3 blue darken-1 rounded-lg",
      };

      return styles[name] || "";
    },
  },
};
