<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
  },
  data: () => ({
    //
  }),
};
</script>

<style>
/* HIDE SCROLL BAR BUT STILL ABLE TO SCROLL */
/* html {
  overflow: scroll;
} */
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
</style>