import { watch } from "vue";
import UserTopNav from "../../components/UserTopNav.vue";
import JwtServices from "../../services/jwt.services";
import {
  SET_BADGE_ACTIVE,
  SET_NOTIF_ACTIVE,
  SET_NOTIF_DATA,
} from "../../store/notification.module";

export default {
  components: { UserTopNav },
  data: () => ({
    navigationModal: false,
    selected_modal: [],
    network_operator: null,
    modal_title: "",
    items: [
      {
        title: "Submit Alarm",
        data: [
          {
            name: "Upload File",
            link: "/dashboard/submit-alarm/upload",
          },
          // {
          //   name: "Create Form",
          //   link: "/dashboard/submit-alarm/form",
          // },
        ],
      },
    ],
    user: null,
    ws: null,
    wsConnected: false,
    wsReconnectAttempts: 0,
    maxReconnectAttempts: 5,
    reconnectTimeout: null,

    notif: false,
    notifData: [],
  }),
  mounted() {
    this.user = JwtServices.getUser();
    console.log(this.user);

    if (!this.user) {
      this.$router.replace({ name: "Login" });
    }

    if (this.user.network_operator.type) {
      this.network_operator = this.user.network_operator.type;
    }
    this.initializeWebSocket();
  },

  // watch: {
  //   notif(newVal) {
  //     if (newVal) {
  //       setTimeout(() => {
  //         console.log("notif mati");
  //         this.notif = false; // Ubah state ke false setelah 1 detik
  //       }, 3000);
  //     }
  //   },
  // },

  methods: {
    showModalNavigation(value) {
      this.modal_title = value;
      for (let item of this.items) {
        if (item.title == value) {
          this.selected_modal = item.data;
          this.navigationModal = true;
        }
      }
    },
    deactivateBadge() {
      this.$store.dispatch(SET_BADGE_ACTIVE, false);
    },

    initializeWebSocket() {
      try {
        const wsUrl = `${process.env.VUE_APP_API_URL}api/v1/id/notifications/ws/conn/${this.user.role_detail.key}`;

        this.ws = new WebSocket(wsUrl, ["token", this.user.token]);

        this.ws.onopen = this.handleWsOpen;
        this.ws.onclose = this.handleWsClose;
        this.ws.onerror = this.handleWsError;
        this.ws.onmessage = this.handleWsMessage;
      } catch (error) {
        console.error("WebSocket initialization error:", error);
      }
    },

    handleWsOpen() {
      console.log("WebSocket Connected");
      this.wsConnected = true;
      this.wsReconnectAttempts = 0;
    },

    handleWsClose(event) {
      console.log("WebSocket Disconnected:", event);
      this.wsConnected = false;

      // Attempt to reconnect if not at max attempts
      if (this.wsReconnectAttempts < this.maxReconnectAttempts) {
        this.wsReconnectAttempts++;
        console.log(
          `Attempting to reconnect (${this.wsReconnectAttempts}/${this.maxReconnectAttempts})`
        );

        this.reconnectTimeout = setTimeout(() => {
          this.initializeWebSocket();
        }, 3000); // Retry after 3 seconds
      } else {
        console.log("Max reconnection attempts reached");
      }
    },

    handleWsError(error) {
      console.error("WebSocket Error:", error);
    },

    handleWsMessage(event) {
      try {
        const data = JSON.parse(event.data);
        this.$store.dispatch(SET_BADGE_ACTIVE, true);
        this.$store.dispatch(SET_NOTIF_ACTIVE, true);
        this.$store.dispatch(SET_NOTIF_DATA, data);

        // this.notifData = data;
        // this.notif = true;
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    },

    handleNotification(data) {
      // Handle notification data
      // Example: Show notification using your preferred notification system
      this.$swal({
        title: "New Notification",
        text: data.message,
        icon: "info",
        timer: 3000,
      });
    },

    closeWebSocket() {
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }
      if (this.reconnectTimeout) {
        clearTimeout(this.reconnectTimeout);
      }
    },
  },
};
