export const SET_BADGE_ACTIVE = "setBadgeActive";
export const SET_NOTIF_ACTIVE = "setNotifActive";
export const SET_NOTIF_DATA = "setNotifData";

// Initial state
const state = {
  isBadgeActive: false,
  notifData: {},
  isNewNotif: false,
};

// Getters
const getters = {
  isBadgeActive: (state) => state.isBadgeActive,
  isNewNotif: (state) => state.isNewNotif,
  notifMsg: (state) => state.notifData.event,
};

// Actions
const actions = {
  [SET_BADGE_ACTIVE]({ commit }, value) {
    commit("setBadgeActive", value);
  },
  [SET_NOTIF_ACTIVE]({ commit }, value) {
    commit("setNotifActive", value);
  },
  [SET_NOTIF_DATA]({ commit }, value) {
    commit("setNotifData", value);
  },
};

// Mutations
const mutations = {
  setBadgeActive(state, value) {
    state.isBadgeActive = value;
  },
  setNotifActive(state, value) {
    state.isNewNotif = value;
  },
  setNotifData(state, value) {
    state.notifData = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
