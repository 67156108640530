<template>
  <div class="text-left">
    <div>
      <v-app-bar
        class="mb-10 mt-0 mx-auto rounded-lg white"
        rounded
        elevation="2"
        width="95vw"
      >
        <v-row class="d-flex justify-space-between align-center">
          <v-col cols="3">
            <v-text-field
              class="rounded-lg"
              background-color="grey lighten-3"
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              solo
              flat
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="auto" class="d-flex justify-space-around align-center">
            <template>
              <div class="text-center">
                <v-menu
                  offset-y
                  nudge-bottom="6"
                  nudge-left="140"
                  max-width="23rem"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      depressed
                      @click="deactivateBadge"
                      class="rounded-lg pa-0"
                      style="min-width: 35px; border-color: #bdbdbd"
                    >
                      <v-badge
                        v-if="$store.getters.isBadgeActive"
                        color="blue"
                        @click=""
                      >
                        <v-icon class="black--text">
                          mdi mdi-bell-outline
                        </v-icon>
                      </v-badge>
                      <v-icon v-else class="black--text">
                        mdi mdi-bell-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title primary-title> Notification </v-card-title>
                    <v-divider> </v-divider>
                    <div v-if="loading">
                      <v-progress-circular
                        class="loader"
                        :size="50"
                        indeterminate
                      >
                      </v-progress-circular>
                      =
                    </div>
                    <v-list
                      v-else
                      max-height="15rem"
                      class="py-0"
                      style="overflow-y: scroll"
                    >
                      <v-list-item
                        v-for="item in notificationData"
                        @click="readNotification(item)"
                        :style="{
                          backgroundColor: item.is_read
                            ? 'transparent'
                            : '#e3f2fd',
                          borderBottom: '1px solid #CFD8DC',
                        }"
                        class="py-1"
                      >
                        <div>
                          <v-avatar size="24" color="blue">
                            <v-icon dense size="17" dark>
                              mdi mdi-bell-outline</v-icon
                            >
                          </v-avatar>
                        </div>
                        <div class="ml-2">
                          <h6 class="font-weight-medium grey--text">
                            {{ item.created_at | dateFormat }}
                          </h6>
                          <h5>
                            {{ item.event }}
                          </h5>
                        </div>
                      </v-list-item>
                    </v-list>

                    <v-divider> </v-divider>
                    <v-card-actions class="justify-center">
                      <v-btn
                        @click="dialog_notif = true"
                        light
                        elevation="0"
                        centered
                        class="blue--text"
                        style="background-color: transparent"
                        >Lihat Semua
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </template>

            <div class="mx-3" style="width: 12rem">
              <v-select
                v-if="user != null"
                class="rounded-lg"
                solo
                flat
                hide-details
                light
                style="border: 0.5px solid #bdbdbd"
                v-model="selected_language"
                :items="language"
                item-value="value"
                dense
              >
                <template v-slot:item="{ item }">
                  <img
                    class="select-flag"
                    :src="item.image"
                    :alt="item.value"
                  />
                  {{ item.name }}
                </template>

                <template v-slot:selection="{ item }">
                  <img
                    class="select-flag"
                    :src="item.image"
                    :alt="item.value"
                  />
                  {{ item.name }}
                </template>
              </v-select>
            </div>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip class="mx-3 py-5 rounded-lg" outlined v-on="on">
                  <v-avatar
                    class="mr-2"
                    v-if="user.network_operator.logo == null"
                    :color="avatarBackground(user.network_operator.name)"
                  >
                    <span class="white--text">
                      {{ stringInitial(user.network_operator.name) }}
                    </span>
                  </v-avatar>

                  <v-avatar v-else left>
                    <v-img
                      :src="`${baseURL}${user.network_operator.logo}`"
                    ></v-img>
                  </v-avatar>
                  {{ user.network_operator.name }}
                </v-chip>
              </template>

              <v-list>
                <v-list-item @click="logout">
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>

                <router-link to="/auth/reset-password">
                  <v-list-item>
                    <v-list-item-title>Change Password</v-list-item-title>
                  </v-list-item>
                </router-link>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-app-bar>

      <!-- DIALOG LIST NOTIFICATION -->
      <v-dialog width="35%" v-model="dialog_notif" scrollable persistent>
        <v-card>
          <v-toolbar
            style="position: sticky; z-index: 99"
            width="100%"
            height="80rem"
            tile
            dark
            color="primary"
          >
            <div>
              <h3>Notifikasi</h3>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog_notif = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list max-height="35rem" style="overflow-y: scroll" class="py-0">
            <v-list-item
              v-for="item in notificationData"
              @click="readNotification(item)"
              :style="{
                backgroundColor: item.is_read ? 'transparent' : '#e3f2fd',
                borderBottom: '1px solid #CFD8DC',
              }"
              class="py-1"
            >
              <div>
                <v-avatar size="24" color="blue">
                  <v-icon dense size="17" dark> mdi mdi-bell-outline</v-icon>
                </v-avatar>
              </div>
              <div class="ml-2">
                <h6 class="font-weight-medium grey--text">
                  {{ item.created_at | dateFormat }}
                </h6>
                <h5>
                  {{ item.event }}
                </h5>
              </div>
            </v-list-item>
          </v-list>
          <v-divider> </v-divider>

          <v-pagination
            v-model="pagination.page"
            class="my-4"
            :total-visible="10"
            :length="pagination.total"
            @input="getNotificationByPage"
          ></v-pagination>
        </v-card>
      </v-dialog>

      <!-- POP UP NOTIFICATION -->
      <v-snackbar
        v-model="isNotif"
        top
        right
        color="blue lighten-5"
        transition="scroll-y-transition"
        elevation="3"
        max-width="20rem"
      >
        <v-row class="align-center mx-0">
          <v-col cols="auto" class="blue darken-2 rounded-pill">
            <v-icon color="white">mdi mdi-bell-ring</v-icon>
          </v-col>
          <v-col cols="10">
            <h4 class="font-weight-medium mb-1 blue--text text--darken-3">
              Notifikasi Baru
            </h4>
            <h4 class="grey--text text--darken-3">{{ dataNotif }}</h4>
          </v-col>
        </v-row>
      </v-snackbar>

      <!-- <div
        v-if="currentPage != 'Landing Page'"
        class="headline font-weight-bold mt-5"
      >
        {{ currentPage }}
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import JwtServices from "../services/jwt.services";
import { ListViewPlugin } from "@syncfusion/ej2-vue-lists";
import { AUTH_SIGNOUT } from "../store/auth.module";
import UK from "../assets/icon/flag/uk.png";
import ID from "../assets/icon/flag/id.png";
import {
  SET_BADGE_ACTIVE,
  SET_NOTIF_ACTIVE,
} from "../store/notification.module";
import moment from "moment";

Vue.use(ListViewPlugin);
export default {
  data: () => ({
    accountList: [{ text: "Logout", id: "002", icon: "logout", to: "Login" }],
    fields: { iconCss: "icon", tooltip: "text" },
    logoutPopup: false,
    mobile: false,
    loading: false,
    notificationData: [],
    dialog_notif: false,
    user: "",
    baseURL: process.env.VUE_APP_API_URL,
    selected_language: "id",
    pagination: {
      page: 1,
      total: 0,
    },
    language: [
      {
        image: ID,
        value: "id",
        name: "Indonesia",
      },
      {
        image: UK,
        value: "en",
        name: "English",
      },
    ],
  }),
  filters: {
    dateFormat(value) {
      return moment(value).format("DD/MM/YYYY - hh:mm:ss");
    },
  },
  computed: {
    currentPage() {
      return this.$store.getters.CurrentPage;
    },

    isNotif() {
      return this.$store.getters.isNewNotif;
    },

    dataNotif() {
      return this.$store.getters.notifMsg;
    },
  },

  watch: {
    isNotif(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$store.dispatch(SET_NOTIF_ACTIVE, false);
        }, 3000);
      }
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name === "md" ||
      this.$vuetify.breakpoint.name === "sm" ||
      this.$vuetify.breakpoint.name === "xs"
    ) {
      this.mobile = true;
    }

    this.user = JwtServices.getUser();
    this.baseURL = process.env.VUE_APP_API_URL;
  },
  methods: {
    AccountMenu: function (event) {
      this.logoutPopup = !this.logoutPopup;
      this.logoutPopupClicked = true;
      if (event != undefined) {
        if (event.data.text == "Settings") {
          this.$router.push({ name: "Profile" });
        }
        if (event.data.text == "Logout") {
          this.$router.push({ name: "Login" });
        }
      }
    },
    logout() {
      this.$store.dispatch(AUTH_SIGNOUT, this.user.id);
      this.$router.push({ name: "Login" });
    },
    deactivateBadge() {
      this.$store.dispatch(SET_BADGE_ACTIVE, false);
      this.getNotification();
    },
    getNotificationByPage(page) {
      this.pagination.page = page;
      this.getNotification();
    },
    async getNotification() {
      const url = `${process.env.VUE_APP_API_URL}api/v1/id/notifications?role=${this.user.role_detail.key}&limit=10&page=${this.pagination.page}&sort=desc`;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.user.token}`,
        },
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.error) {
          this.$swal("Opps", data.message, "error");
        } else {
          this.notificationData = data.data.data;
          this.pagination.total = data.data.pagination.total_page;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async readNotification(item) {
      const ids = [item.id];
      console.log(item.type, "tipe notif");
      const url = `${process.env.VUE_APP_API_URL}api/v1/id/notifications/status`;
      const options = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.user.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids }),
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.error) {
          this.$swal("Opps", data.message, "error");
        } else {
          this.getNotification();
          this.redirectToPage(item.type);
        }
      } catch (error) {
        console.error(error);
      }
    },

    redirectToPage(type) {
      const routes = {
        alarm: "/dashboard/alarm-fo",
        comment: "/dashboard/ticketing",
      };

      // Cek route sesuai dengan tipe notifikasi
      if (!routes[type]) return;

      // Cek akses ke url
      if (window.location.pathname !== routes[type]) {
        this.$router.push(routes[type]);
      }
      return;
    },
  },
};
</script>

<style scoped>
div[role="menu"] {
  border-radius: 1rem;
}

.select-flag {
  height: 2em;
  margin-right: 1em;
}
</style>
